import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

export default function About() {
  const [hover, setHover] = useState(null);

  // const title = sanityAbout?.title?.split("stake");
  // const stakeIndex = sanityAbout?.title?.indexOf("stake");

  return (
    <Layout>
      <div className="relative">
        <div className="absolute top-48 right-0 bottom-0 left-0 -z-10">
          <StaticImage
            src="../images/purple-center.webp"
            alt="logo"
            className="centerbg"
          />
        </div>
        <div className="max-w-[1366px] mx-auto px-5 lg:px-0">
          <div className="pt-[105px] mx-auto max-w-[850px]">
            <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white text-center">
                <span>STAKING & DELEGATION TERMS AND CONDITIONS</span>
            </p>
            <div className="text-white terms-text">

               
              <p class="p2"><span class="s2">Updated:&nbsp;&nbsp; February 14, 2023</span></p>
              <p class="p4">
                  <span class="s2">
                      Welcome to Thunderhead Interests, LLC [TI] &ndash;This Privacy Policy relates to our information collection and use practices in connection with our technology platform, software, and services (the &ldquo;Platform&rdquo;) and our
                      publicly available website (the &ldquo;Website&rdquo;). Capitalized&nbsp;terms not defined in this Privacy Policy shall have the meaning set forth in our&nbsp;Terms and Conditions.
                  </span>
              </p>

      
                <p class="p2">The following sets forth the terms and conditions (these &ldquo;Terms and Conditions&rdquo;) for the Services (as defined herein) of Thunderhead Interests, LLC a Texas limited liability company (&ldquo;TI&rdquo;). You are hereby advised that, by accessing and using our Website and Services you will be bound by these Terms and Conditions. Please read all of these Terms and Conditions and confirm your assent by clicking the link provided below and following the directions to create an account; provided, however, ACCESSING AND USING THE SERVICES WILL BIND YOU TO THE TERMS AND CONDITIONS SET FORTH HEREIN REGARDLESS OF YOUR EXPRESS ASSENT TO THESE TERMS AND CONDITIONS.</p>
                <p class="p2">You are further advised to review our Privacy Policy, which outlines our handling of any personal information that you may provide to TI online, and the Terms of Service with respect to the Website (as defined herein).</p>
                <p class="p2">You and TI agree that your access to and use of all Services, the Website, and Platform are subject to these Terms and Conditions, which will, upon the earlier of your assent to the Terms and Conditions as provided herein or accessing and utilizing the Services, become a binding agreement between you and TI (the &ldquo;Agreement&rdquo;). TI is willing to allow you to access the Website and Platform, and to perform the Services, with the understanding that such activity binds you to the Agreement. Read these Terms and Conditions carefully, including the Definitions section. After reading the terms and conditions, you are encouraged to indicate your assent by clicking the link &ldquo;[Create Your TI Staking Account]&rdquo; to delegate Tokens (as such term defined in this Agreement) to TI. If you do not agree with all of the Terms and Conditions of this Agreement, do not access the Services.</p>
                
                <p class="p3">RECITALS:</p>
                <p class="p4">A proof-of-stake system requires the protocol<span class="s1">&rsquo;</span>s token-holders to stake their tokens to participate in the consensus validation process. A proof-of-stake system enables at least one of the staking token-holders to validate and sign the next definitive serial transaction record. To incentivize staking and validation, the proof-of-stake system grants additional tokens as an award for performing staking processes. Because validating and signing transaction records requires certain computational infrastructure, some token-holders choose to delegate management of staking processes to third-party validators; TI offers non-custodial staking to other token-holders through TI<span class="s1">&rsquo;</span>s proprietary computational systems that facilitate the management of delegated staking processes. You own tokens and wish to delegate the technical and administrative management of the staking of your Tokens to TI on the terms of this Agreement, it being agreed and understood that you shall be responsible for all decisions relating to which specific validators to delegate, as well as the timing of when to withdraw or unstake Tokens.</p>

                <ul style={{listStyleType: "numeric"}}>

                  <li class="li6"><span class="s4"><strong>DEFINITIONS</strong></span></li>

                  <p class="p3">The definitions for some defined terms used in this Agreement are set forth below.<span class="Apple-converted-space">&nbsp; </span>Other terms may be defined elsewhere in this Agreement.</p>
                  <ol class="ol2" style={{listStyleType: "lower-alpha"}}>

                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Additional Terms&rdquo; means certain terms in respect of the delegation of Tokens of each Supported Blockchain, as described in the Schedule(s) attached to this Agreement. The Additional Terms are subject to the protocols of the Supported Blockchain and changes of such protocols imposed by the respective Supported Blockchain. </span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Affiliate&rdquo; means, with respect to any entity, any other entity that, directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such entity. The term </span><span class="s6">&ldquo;</span><span class="s4">control&rdquo; means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of an entity, whether through the ownership of voting securities, by contract, or otherwise.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Agreement&rdquo; is as defined herein, and includes the Additional Terms and the Terms of Service and Privacy Policy, as any of the foregoing may be updated, supplemented, or amended from time to time as provided herein.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Blockchain Protocols&rdquo; means any protocols or operations of the Supported Blockchain, including the rules governing the validation and inclusion of transactions in the Supported Blockchain.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Confidential Information&rdquo; means: (i) with respect to TI, the Platform, the Website, and any and all source code relating thereto and any other non-public information or material regarding TI&rsquo;s legal or business affairs, financing, customers, properties, pricing, or data; and (ii) with respect to you, any non-public information or material regarding your legal or business affairs, financing, customers, properties, or data.<span class="Apple-converted-space">&nbsp; </span>Notwithstanding any of the foregoing, Confidential Information does not include information which:<span class="Apple-converted-space">&nbsp; </span>(a) is or becomes public knowledge without any action by, or involvement of, the Party to which the Confidential Information is disclosed (the </span><span class="s6">&ldquo;</span><span class="s4">Receiving Party</span><span class="s6">&ldquo;</span><span class="s4">); (b) is documented as being known to the Receiving Party prior to its disclosure by the other Party (the </span><span class="s6">&ldquo;</span><span class="s4">Disclosing Party</span><span class="s6">&ldquo;</span><span class="s4">); (c) is independently developed by the Receiving Party without reference or access to the Confidential Information of the Disclosing Party and is so documented; or (d) is obtained by the Receiving Party without restrictions on use or disclosure from a third party who has not breached any obligation of confidentiality to the Disclosing Party or any third party.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Effective Date&rdquo; means the earlier of the date you create an account and date on which you first delegate any Token(s) to TI.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Force Majeure Events&rdquo; has the meaning given to it in Section 15.10.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Law&rdquo; means any applicable national, provincial, international, federal, state, county, and local statute, law, ordinance, regulation, rule, code, and order.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Net Rewards&rdquo; means the sum of the Rewards, subject to reimbursement terms set forth in Section 3(e).</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Party&rdquo; means TI or you, as applicable; and </span><span class="s6">&ldquo;</span><span class="s4">Parties&rdquo; means, together, TI and you.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Person&rdquo; means any individual, organization, business, partnership, entity, corporation, or government.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Platform&rdquo; means TI</span><span class="s6">&rsquo;</span><span class="s4">s proprietary computational infrastructure or platform that it uses to perform the Services.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Prohibited Content&rdquo; means content that: (i) is illegal under Law; (ii) violates any third party</span><span class="s6">&rsquo;</span><span class="s4">s intellectual property rights, including copyrights, trademarks, patents, and trade secrets; (iii) contains indecent or obscene material; (iv) contains libelous, slanderous, or defamatory material, or material constituting an invasion of privacy or misappropriation of publicity rights; (v) promotes unlawful or illegal goods, services, or activities; (vi) contains false, misleading, or deceptive statements, depictions, or sales practices; or (vii) contains viruses, Trojan horses, worms, or any other harmful, malicious, or hidden procedures, routines, mechanisms, or code.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Rewards&rdquo; means any rewards granted by the Supported Blockchain, including block rewards, endorser rewards, and transaction fees, in each case as actually granted by the Supported Blockchain and received by TI or you, as applicable, in connection with the performance of the Services. &ldquo;Your Rewards&rdquo; &rdquo; means the Net Rewards described at Sections 5.1(a).</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Service&rdquo; or &ldquo;Services&rdquo; means the exercise by TI of Token Rights on behalf of you under this Agreement, as described in Section 3.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Supported Blockchain&rdquo; means any proof-of-stake network or blockchain ledger on which TI may exercise Token Rights delegated to it by you. Each Supported Blockchain has its own protocols and terms. The Supported Blockchains and some applicable terms are identified in the Additional Terms.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Term&rdquo; has the meaning given under Section 7.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Token&rdquo; means any digital blockchain assets (whole or fractional) that you have delegated to TI in accordance with the protocol of the applicable Supported Blockchain. When such term is not capitalized in this agreement, it is a general reference to digital blockchain assets as opposed to those that have been delegated hereunder.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Token Rights&rdquo; means, together, Validation Rights and Voting Rights.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Validation Rights&rdquo; means rights of a Token owner to validate and sign the next definitive serial transaction record on a Supported Blockchain.</span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Unbonding Period&rdquo; means the waiting period during which tokens may not be withdrawn or sold from the Supported Blockchain. </span></li>
                    <li class="li7"><span class="s6">&ldquo;</span><span class="s4">Voting Rights&rdquo; means rights of a Token owner to vote upon proposals related to the operation and governance of the respective Supported Blockchain.</span></li>
                    <li class="li8"><span class="s6">&ldquo;</span><span class="s4">Website&rdquo; means <a href="https://TI.com">https://TI.com</a>.</span></li>
                </ol>


                <li>
                  <p>
                    <span class="s2"><strong>DELEGATION</strong></span>
                  </p>
                </li>               
                <span class="s2">Subject to the protocols of the Supported Blockchain and unless otherwise provided in the Additional Terms, by creating an account and interacting directly with the protocols of the Supported Blockchain:</span>
                <ol class="ol3">
                  <li class="li3"><span class="s2">You may delegate any number of Tokens to TI under this Agreement at any time during the Term; and</span></li>
                  <li class="li3"><span class="s2">You may initiate the process of withdrawing its Tokens at any time unless prohibited by the terms of use of the Supported Blockchain.</span></li>
                  <li class="li2">
                      <span class="s2">
                          You shall not delegate any Tokens to TI hereunder if you could reasonably expect that any condition described in Section 5 is not satisfied and, in the event that any such condition ceases to be satisfied, then you shall
                          promptly deliver written notice of the same to TI and initiate the process of withdrawing its Tokens.
                      </span>
                  </li>
                </ol>


                <li class="li1"><span class="s2"><strong>SERVICES</strong></span></li>

                <p class="p2">Subject to the terms of this Agreement, TI will perform the following services in any manner considered reasonable by TI in its sole discretion to (together, the <span class="s3">&ldquo;</span>Services<span class="s3">&ldquo;</span>):</p>
            
                  <ol class="ol2">
                  <li class="li3"><span class="s2">stake the Tokens by exercising the Validation Rights in a manner reasonably intended to generate Net Rewards; and</span></li>
                  <li class="li3"><span class="s2">vote the Tokens, if applicable, by exercising the Voting Rights in a reasonable manner, unless the you elect to exercise the Voting Rights in accordance with the protocols of the applicable Supported Blockchains.</span></li>
                  <li class="li3"><span class="s2">Consolidation. TI may perform the Services in any commercially reasonable manner, including by consolidating the Token Rights delegated by you to TI under this Agreement with other Validation Rights and Voting Rights delegated to or otherwise exercised by TI.</span></li>
                  <li class="li4"><span class="s2">Withdrawal and Unbonding</span>
                  <ol class="ol3">
                  <li class="li4"><span class="s2">Tokens withdrawn by you may be subject to unbonding periods imposed by the protocols of the Supported Blockchain.</span></li>
                  <li class="li3"><span class="s2">Tokens and Net Rewards may be unavailable to you during the unbonding periods and subject to other restrictions imposed by the Supported Blockchain.</span></li>
                </ol>
                </li>
                <li class="li4"><span class="s2">To the extent that slashing or other penalties are imposed by any protocol that supports slashing (without limitation, Ethereum, Polkadot, etc.) that are the direct result of TI&rsquo;s acts or omissions impacting your Tokens, upon your written request TI may, at its sole but reasonable discretion, provide a refund of not more than the equivalent of 0.1 ETH to you. Under no circumstances will TI reimburse you for any slashing or other penalties that are the result of your acts or omissions, schedule maintenance with respect to any protocol, software bugs native to any protocol, or acts by any hacker or other malicious actor. </span></li>
                </ol>

                <li class="li1">
                    <span class="s2">REWARDS</span>
                    <ol class="ol2" style={{listStyleType: "lower-alpha"}}>
                        <li class="li1">
                            <span class="s2">
                                Transfer of Net Rewards.<span class="Apple-converted-space">&nbsp; </span>The Parties acknowledge that the performance of the Services by TI is expected to result in the transfer of Net Rewards by the Supported
                                Blockchain as follows:
                            </span>
                            <span class="s4"><br /></span>
                            <ol class="ol3">
                                <li class="li1"><span class="s2">to you by transferring Net Rewards to the wallet address from which you delegated the Tokens to TI under this Agreement;</span></li>
                                <li class="li1"><span class="s2">to TI as a service fee as set forth in the Payment Addendum or as a pass through to you; </span></li>
                                <li class="li1">
                                    <span class="s2">if the Supported Blockchain requires the transfer of rewards to you, TI will invoice you for TI</span><span class="s5">&rsquo;</span>
                                    <span class="s2">s portion according to the formula set forth in the Payment Addendum&rdquo;</span>
                                </li>
                                <li class="li1"><span class="s2">the Net Rewards may be in the same denomination as the Tokens that you delegated to TI under this Agreement.</span></li>
                            </ol>
                        </li>
                        <li class="li3"><span class="s2">This Section is subject to the protocols of the Supported Blockchain and any variations in the transfer of Rewards described under the Additional Terms.</span></li>
                        <li class="li3">
                            <span class="s2">
                                Rewards Not Guaranteed. You acknowledge that the transfer of Net Rewards by the Supported Blockchain is not guaranteed, you may not receive your Rewards, and that TI is not responsible in any way for any failure by the
                                Supported Blockchain to transfer Net Rewards to you or the loss, destruction or transfer of Net Rewards to the incorrect wallet address provided by you.
                            </span>
                        </li>
                    </ol>
                </li>


      
              <li class="li1">
                  <span class="s2">Protocol Changes, Airdrops &amp; Forks<br /></span>&nbsp;
                  <ol class="ol2" style={{listStyleType: "lower-alpha"}}>
                      <li class="li2">
                          <span class="s2">
                              The Parties acknowledge and agree that Supported Blockchain protocols may change, and airdrops or forks may arise, in each case outside of the control of TI and that, therefore, except as may be otherwise provided in
                              this Agreement:
                          </span>
                      </li>
                  </ol>


                  
              </li>
           
             
                <ol style={{listStyleType: "lower-roman", marginLeft: "10px"}}>
                
                  <li class="p3">TI may respond to protocol changes, airdrops or forks in any way that TI determines appropriate in its sole discretion acting reasonably;</li>
                  <li>
                      the exercise by TI of any right or power that is available to it in its capacity as a validating node on the Supported Blockchain shall not constitute a breach or violation any obligation owed by TI to you under this
                      Agreement; and
                  </li>
                  <li class="p3">
                      <p class="p1">TI is not responsible for any losses, liabilities, damages, or reductions in value in respect of the Tokens or otherwise suffered by you in connection with protocol changes, airdrops or forks.</p>
                  </li>

                  <ol style={{listStyleType: "lower-alpha"}}>
                    <li>
                      In the event that a Supported Blockchain undergoes a change imposed by such protocol, such protocol change shall be deemed to be incorporated into and supersede any conflicting terms of the Additional Terms if determined by
                        TI in its sole discretion.
                    </li>
                  </ol>
                </ol>
                    
                

            
                <li class="li1">
                  <span class="s2">CONDITIONS</span>
                  <ol class="ol2" style={{listStyleType: "lower-alpha"}}>
                      <li class="li1">
                          <span class="s2">The obligation of TI to perform the Services is conditional on the satisfaction of the following conditions precedent as of the Effective Date and the time(s) of performance of the Services:</span>
                          <span class="s4"><br /></span>
                          <ol class="ol3" style={{listStyleType: "lower-roman"}}>
                              <li class="li1"><span class="s2">the Supported Blockchain has selected TI to operate a validator node on the Supported Blockchain;</span></li>
                              <li class="li1"><span class="s2">your covenants and obligations under this Agreement are performed and satisfied in all material respects;</span></li>
                              <li class="li1"><span class="s2">your representations and warranties set forth in Section 12 are true, accurate and complete as of all times on and after the date of this Agreement during the Term</span></li>
                              <li class="li1">
                                  <span class="s2">Neither your delegation by of the Token Rights to TI, nor TI</span><span class="s5">&rsquo;</span>
                                  <span class="s2">
                                      s performance of Services for you, constitute, or would be reasonably expected to result in (with or without notice, lapse of time, or both) a breach, default, contravention or violation of any Law, or agreement to
                                      which you or TI is a party or by which you or TI is bound, including this Agreement and the protocols of the Supported Blockchain; and
                                  </span>
                              </li>
                          </ol>
                      </li>
                      <li class="li1">
                          <span class="s2">Without limiting the generality of the foregoing, under applicable Law:</span>
                          <ol class="ol3" style={{listStyleType: "lower-roman"}}>
                              <li class="li1">
                                  <span class="s2">TI is not deemed to be a </span><span class="s5">&ldquo;</span>
                                  <span class="s2">money transmitter&rdquo; or a similar classification in accordance with applicable anti-money laundering, anti-terrorist or anti-terrorist financing, know-your-customer or similar Laws; and</span>
                              </li>
                              <li class="li1">
                                  <span class="s2">the performance of this Agreement, including the Services, by TI does not require any licenses, permits, or registrations (in respect of securities Law or otherwise) not possessed by TI.</span>
                              </li>
                          </ol>
                      </li>
                  </ol>
              </li>

              <li class="li1">
                  <span class="s2">TAXES</span>
                  <ol class="ol2" style={{listStyleType: "lower-alpha"}}>
                      <li class="li2">
                          <span class="s2">You shall be solely responsible for the payment to applicable governmental authorities of any and all taxes, penalties, duties, levies, and interest (together, </span><span class="s4">&ldquo;</span>
                          <span class="s2">Taxes</span><span class="s4">&ldquo;</span>
                          <span class="s2">
                              ) (i) applicable to the your Rewards and other amounts receivable or received by you in connection with this Agreement, and (ii) all your other Taxes or which may apply to you resulting from or related to the transactions
                              contemplated under this Agreement. You shall indemnify and hold harmless TI and its indemnified parties as described herein in respect of all Taxes levied, applied or assessed by any governmental authority on any Net Rewards
                              and other amounts receivable or received by you in connection with this Agreement, in accordance with Section 14.
                          </span>
                      </li>
                      <li class="li2" style={{listStyleType: "lower-alpha"}}>
                          <span class="s2">
                              Neither TI nor any of its agents have provided or will provide advice or guidance with respect to any Law, applicable Tax or any of your other obligations.<span class="Apple-converted-space">&nbsp; </span>You are strongly
                              encouraged to seek advice from your own legal and tax advisors with respect to any Law, applicable Tax and your other obligations related to the entering into and performance of this Agreement.
                          </span>
                      </li>
                  </ol>
              </li>

              <li>
                  <p>TERM, TERMINATION, AND SURVIVAL</p>
                  <ol>
                      <li style={{listStyleType: "lower-alpha"}}>
                          <p>Term. The term of this Agreement (the &ldquo;Term&ldquo;) commences on the Effective Date and shall continue in effect until terminated in accordance with this Section.&nbsp;&nbsp;</p>
                      </li>
                      <li style={{listStyleType: "lower-alpha"}}>
                          <p>
                              Termination.&nbsp; Either Party may, any time and for any reason whatsoever, terminate this Agreement (i) on delivery of written notice of termination to the other Party, (ii) in the case of you, by withdrawing your
                              Tokens, or (iii) in the case of TI, by taking steps to terminate the delegation of your Tokens to TI; in each case, the termination is subject to any restrictions on termination provided in the Additional Terms and the
                              terms of the Supported Blockchain.
                          </p>
                      </li>
                      <li style={{listStyleType: "lower-alpha"}}>
                          <p>Effect of Termination.&nbsp; Upon any termination of this Agreement:</p>
                          <ol>
                              <li style={{listStyleType: "lower-roman"}}>
                                  <p>You will cease delegating Tokens to TI;</p>
                              </li>
                              <li style={{listStyleType: "lower-roman"}}>
                                  <p>You will initiate re-delegation and unbonding of the Tokens, as applicable;</p>
                              </li>
                              <li style={{listStyleType: "lower-roman"}}>
                                  <p>You will promptly make payment of all outstanding amounts owing to TI under this Agreement; and</p>
                              </li>
                              <li style={{listStyleType: "lower-roman"}}>
                                  <p>
                                      Upon reasonable written request, each Party shall either return to the other Party (or, at such other Party&rsquo;s instruction, destroy and provide such other Party with written certification of the destruction
                                      of) all documents, computer files, and other materials containing any of such other Party&rsquo;s Confidential Information that are in its possession or control, subject to applicable Law.
                                  </p>
                              </li>
                          </ol>
                      </li>
                      <li style={{listStyleType: "lower-alpha"}}>
                          <p>Survival. Any provision of this Agreement which by its nature is intended to survive termination of this Agreement shall survive termination of this Agreement.</p>
                      </li>
                  </ol>
              </li>

              <li>CONFIDENTIALITY</li>
              <p>
                  The Receiving Party will: (i) protect the confidentiality of the Disclosing Party&rsquo;s Confidential Information using the same degree of care that it uses with its own confidential information of similar nature, but with no less than
                  reasonable care; (ii) not use any of the Disclosing Party&rsquo;s Confidential Information for any purpose other than as may be necessary or desirable in connection with the performance of this Agreement; and (iii) not disclose the
                  Disclosing Party&rsquo;s Confidential Information to any party other than its employees, contractors, and advisors, as reasonably required to perform this Agreement provided they are bound by obligations of confidentiality at least as
                  restrictive as those set forth in this Agreement.&nbsp; If the Receiving Party is legally compelled by court of governmental agency order or subpoena to disclose any of the Disclosing Party&rsquo;s Confidential Information, the
                  Receiving Party will provide the Disclosing Party prompt prior written notice of such requirement so that the Disclosing Party may seek a protective order or other appropriate remedy and/or waive compliance with the terms of this
                  Section.&nbsp; If such protective order or other remedy is not obtained or the Disclosing Party waives compliance with the terms of this Section, the Receiving Party may furnish only that portion of the Confidential Information which it
                  is legally required to disclose in the opinion of its counsel.
              </p>
             
              <li>INTELLECTUAL PROPERTY</li>
              <p>All right, title, and interest in and to the Platform and the Website, including all modifications, improvements, adaptations, and enhancements made thereto, are and shall remain sole and exclusive property of TI.&nbsp;</p>
      
              <li>
                  LIMITATIONS OF USE
                  <ol style={{listStyleType: "lower-alpha"}}>
                      <li>The Terms of Service and Privacy Policy of TI provided on the Website govern your access and use of the Website and are incorporated into this Agreement. &nbsp;</li>
                      <li>
                          You shall not, and will not, authorize, permit, or encourage any third party to: (i) reverse engineer, decompile, disassemble, or otherwise attempt to discern the source code or interface protocols of the Platform or the
                          Website; (ii) modify, adapt, or translate the Platform or the Website; (iii) make any copies of the Platform or the Website; (iv) resell, distribute, or sublicense the Platform or the Website; (v) remove or modify any
                          proprietary marking or restrictive legends placed on the Platform or the Website; (vi) use the Platform or Website (A) in violation of any Law or regulation, (B) to build a competitive product or service, or (C) for any
                          purpose other than to perform this Agreement; nor (vii) introduce, post, upload, transmit, or otherwise make available to or from the Platform or the Website any Prohibited Content.
                      </li>
                      <li>
                          You hereby acknowledge and agree that TI will not accept or take custody over any Rewards on your behalf, has no responsibility or control over whether the Supported Blockchain distributes any Rewards to you, and that
                          TI&rsquo;s sole obligation under this Agreement is to perform the Services, upon the terms and conditions set forth in this Agreement.&nbsp; Notwithstanding the foregoing sentence, TI may accept transaction fees on your
                          behalf if you are not set up to receive transaction fees directly.
                      </li>
                  </ol>
              </li>

              <li>
                  <p>REPRESENTATIONS AND WARRANTIES; DISCLAIMER</p>
              </li>
                <ol style={{listStyleType: "lower-alpha"}}>
                  <li>
                      <p>Mutual Representations and Warranties. Each Party represents and warrants to the other Party, as of the Effective Date and each date on which you have delegated Token Rights to TI, that:</p>
                  </li>
                  <ol>
                    <li style={{listStyleType: "lower-roman"}}>
                        <p>
                            If the Party is an individual, the Party is of legal age in its jurisdiction of residence and is of sound mind and body. If the Party is a business entity, partnership or other organization (each, a &ldquo;Business
                            Entity&ldquo;), the Party is duly organized and existing in good standing under the Laws of its jurisdiction of organization; and
                        </p>
                    </li> 
                    <li style={{listStyleType: "lower-roman"}}>
                        <p>
                            The Party has all required capacity, authority and power to enter into and perform its obligations under this Agreement, has duly authorized the same by corporate action by its governing body, and this Agreement constitutes a
                            legal, valid and binding obligation of the Party enforceable against the Party in accordance with its terms, except as limited by bankruptcy, insolvency or other laws of general application relating to or affecting the
                            enforcement of creditors&rsquo; rights generally and principles of equity.
                        </p>
                    </li>

                    
                </ol>
                <li style={{listStyleType: "lower-alpha"}}>
                      <p>
                          Your Representations and Warranties.&nbsp; In addition to the representations and warranties set forth in Section 12.1, you represents and warrants to TI, as of the Effective Date and each date on which you delegate Token Rights
                          to TI, that:
                      </p>
                      <ol>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>You have all right, title, and interest in and to the Tokens;</p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  Your execution, delivery, and performance of this Agreement (i) does and will not conflict with or violate in any Law, and (ii) are not in violation or breach of, and will not conflict with or constitute a default under,
                                  any contract, agreement, or commitment binding upon you;
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>You are not entering into this Agreement or delegating Token Rights to TI for the purpose of making an investment with respect to TI or its securities, but instead, and only, to receive the Services from TI;</p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  Without limiting the foregoing, you acknowledge and agree that the entering into and performance of this Agreement by each Party (including the delegation of Token Rights by you to TI) does not (a) represent or
                                  constitute a loan or a contribution of capital to, or other investment in, TI; (b) provide you with any ownership interest, equity, security, or right to or interest in the assets, rights, properties, revenues or profits
                                  of, or voting rights whatsoever in, TI; or (c) create or imply any fiduciary or other agency relationship between TI (or any of its directors, officers, employees, agents, or Affiliates) and you or entitle you to any
                                  fiduciary duty or similar duty on the part any of the foregoing Persons;
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  You, your agents (and, if you are a Business Entity, your officers, directors, and employees (collectively, the &ldquo;Representatives&ldquo;)) are in compliance with the <em>Criminal Code </em>(Canada),
                                  <em>Proceeds of Crime (Money Laundering) and Terrorist Financing Act </em>(Canada), the <em>Foreign Corrupt Practices Act of 1977 </em>(United States), in each case as amended, and any rules and regulations thereunder
                                  respectively, similar Laws of other jurisdictions, anti-money laundering obligations, and anti-terrorist financing obligations under the Law of Canada, United States, and/or your jurisdiction, and as otherwise applicable
                                  to you, its Representatives, and/or TI;
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  You have not (and, if you are a Business Entity, your Representatives have not) been convicted of, or have agreed to enter into a pretrial diversion or similar program in connection with the prosecution of, a criminal
                                  offense involving theft, dishonesty, breach of trust, money laundering, the illegal manufacture, sale, distribution of or trafficking in controlled substances, or substantially equivalent activity in a domestic,
                                  military, or foreign court;
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  You are not (and, if you are a Business Entity, your Representatives are not) (i) a Person described or designated in the Specifically Designated Nationals and Blocked Persons List of the U.S. Department of Treasury
                                  Office of Foreign Assets Control, Section I of the U.S. Anti-Terrorism Order, the Regulations Establishing a List of Entities under s.83.05(1) of the Criminal Code of Canada, any regulations promulgated under
                                  Canada&rsquo;s Special Economic Measures Act, United Nations Act, Justice for Victims of Corrupt Foreign Officials Act, Freezing of Assets of Corrupt Foreign Officials Act, or the United Nations Security Council
                                  Consolidated Sanctions List or (ii) engaged in any dealings or transactions with any such Person;
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  Without limiting the generality of the foregoing, you are not, and are not owned or controlled by, or acting on behalf of, any Person who is, identified on any list of prohibited parties under any Law or by any
                                  governmental authorities, such as any lists maintained by the United Nations Security Council, the U.S. government (including the U.S. Treasury Department&rsquo;s Specially Designated Nationals list and Foreign Sanctions
                                  Evaders list), the Canadian government, the European Union (EU) or its member states, and the government of your home country. Are not, and are not owned or controlled by, or acting on behalf of, any Person who is,
                                  located, ordinarily resident, organized, established, or domiciled in Cuba, Iran, North Korea, Sudan, Syria, the Crimea region of Ukraine (including Sevastopol) or any other country or jurisdiction against which the U.S.
                                  or Canada maintains comprehensive economic sanctions or an arms embargo. The Tokens are not derived from, and do not otherwise represent the proceeds of, any activities done in violation or contravention of any Law; and
                              </p>
                          </li>
                          <li style={{listStyleType: "lower-roman"}}>
                              <p>
                                  You are sophisticated and experienced in using and evaluating the Supported Blockchain and applicable protocols and related technologies. You have conducted your own due diligence and analysis of the Supported Blockchain
                                  and the matters provided under this Agreement in order to determine whether you wish to enter into this Agreement and delegate Token Rights to TI in order that TI may perform the Services. You have not relied upon any
                                  information, statement, omission, representation or warranty, express or implied, written or oral, made by or on behalf of TI in connection with the entering into and performance of this Agreement by the Parties.
                              </p>
                          </li>
                      </ol>
                  </li>
                  <li style={{listStyleType: "lower-alpha"}}>
                      <p>
                          Disclaimer. Except as expressly set forth herein, the Services, the Platform, the Website, their components, and any other materials or services provided hereunder are provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo;.
                          TI does not make any warranties with respect to the same or otherwise in connection with this Agreement (except as expressly provided in this Agreement) and hereby disclaims any and all express, implied, or statutory warranties,
                          including any warranties of non-infringement, merchantability, fitness for a particular purpose, availability, error-free or uninterrupted operation, and any warranties arising from a course of dealing, course of performance, or
                          usage of trade. To the extent that TI may not as a matter of Law disclaim any warranty, the scope and duration of such warranty will be reduced the minimum permitted under such Law. Without limiting the foregoing, TI makes no
                          representations or warranties with regard to the amount of Net Rewards that may be generated under this Agreement.&nbsp; You understand TI employs measures to ensure that the Services are accessible 24 hours a day/7 days a week,
                          but TI cannot guarantee the uninterrupted or error-free operation performance of the Services or that TI will correct all defects or prevent third party disruptions or unauthorized third party access.
                      </p>
                  </li>
                </ol>
                <li>
                  <p>LIMITATION OF LIABILITY</p>
                  <ol>
                       <li style={{listStyleType: "lower-alpha"}}>
                          <p>
                              In no event will TI be liable or responsible in any way to you or any other party for any incidental, indirect, consequential, special, exemplary, or punitive damages or losses of any kind (including lost Rewards, revenues
                              or profits) arising from or relating to this Agreement, including the Services, regardless of whether TI was advised, had other reason to know, or in fact knew of the possibility thereof.
                          </p>
                      </li>
                       <li style={{listStyleType: "lower-alpha"}}>
                          <p>
                              TI&rsquo;s aggregate liability for damages under this Agreement will not exceed the amount of service fees received by TI hereunder during the period that is six months prior to the event giving rise to the liability or
                              damages.
                          </p>
                      </li>
                       <li style={{listStyleType: "lower-alpha"}}>
                          <p>
                              Without limiting the foregoing, TI will not be liable to you or any other party for any damages or losses of any kind arising from or relating to any malfunction or failure of the Supported Blockchain or any Force Majeure
                              Event.
                          </p>
                      </li>
                  </ol>
              </li>

              <li>
                  <p>INDEMNIFICATION</p>
              </li>
              <p>
                  You (in such capacity, the &ldquo;Indemnifying Party&ldquo;) shall defend, indemnify, and hold harmless the TI and its Affiliates and their respective officers, directors, shareholders, managers, agents, advisors, representatives and
                  employees (together, the &ldquo;Indemnified Party&ldquo;), as applicable, from all liabilities, damages, costs, and reasonable expenses (including reasonable attorneys'&nbsp; fees) incurred by any Indemnified Party in connection with
                  any third-party (including governmental) action, claim, proceeding, or any other damage, cost or liability (each, a &ldquo;Claim&ldquo;) arising from or in connection with the Indemnifying Party&rsquo;s breach or non-performance of its
                  covenants and representations and warranties under this Agreement; provided that the foregoing obligations shall be subject to the Indemnified Party providing the Indemnifying Party, at the expense of the Indemnifying Party, with
                  reasonable cooperation in the defense of the Claim. For clarity, the Indemnified Party shall be entitled to maintain sole control over the defense and negotiations of the Claim for a settlement or other resolution, and the same shall
                  not waive or reduce the obligations of the Indemnifying Party hereunder.
              </p>


              <li>
                <p>GENERAL PROVISIONS</p>
                <ol>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Interpretation</p>
                <ol>
                <li style={{listStyleType: "lower-roman"}}>
                <p>Headings. The headings in this Agreement are for reference only and will not affect the interpretation of this Agreement.</p>
                </li>
                <li style={{listStyleType: "lower-roman"}}>
                <p>References to Agreements. The term &ldquo;Agreement&rdquo; and any reference to this Agreement or any other agreement or document includes, and is a reference to, this Agreement or such other agreement or document as it may have been, or may from time to time be amended, restated, replaced, supplemented or novated.</p>
                </li>
                <li style={{listStyleType: "lower-roman"}}>
                <p>Non-Strict Construction. The language used in this Agreement is the language chosen by the Parties to express their mutual intent, and no rule of strict construction will be applied against a Party.</p>
                </li>
                </ol>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Other Terms.</p>
                <ol>
                <li style={{listStyleType: "lower-roman"}}>
                <p>The words &ldquo;including&rdquo;, &ldquo;includes&rdquo;, and &ldquo;include&rdquo; mean &ldquo;including (or includes or include) without limitation&rdquo;.</p>
                </li>
                <li style={{listStyleType: "lower-roman"}}>
                <p>Any reference in this Agreement to a Person includes his, her, or its heirs, administrators, executors, legal representatives, successors, and permitted assigns, as applicable.</p>
                </li>
                <li style={{listStyleType: "lower-roman"}}>
                <p>Any reference in this Agreement to gender includes all genders, and words importing the singular number only include the plural and vice-versa.</p>
                </li>
                </ol>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Assignment. You shall not assign or otherwise transfer any of its rights or obligations under this Agreement without the prior written consent of TI.&nbsp; Any assignment or other transfer in violation of this Section will be null and void.&nbsp; Subject to the foregoing, this Agreement will be binding upon and inure to the benefit of the Parties and their heirs, estates, legal representatives, successors, and permitted assigns, as applicable.&nbsp;&nbsp;</p>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Waiver.&nbsp; No failure or delay by TI in exercising any right or remedy under this Agreement shall operate or be deemed as a waiver of any such right or remedy. Without limiting the generality of the foregoing, TI shall not be deemed to have waived any of the conditions described in Section 6.1, or waived or released any claim, right, power, privilege or remedy related thereto, by virtue of providing Services to you, and may terminate its Services to you after learning of such non-satisfaction irrespective of how long such condition has not been satisfied.</p>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Governing Law.&nbsp; This Agreement shall be governed by and construed in accordance with the Laws of the State of Texas, without regard for choice of Law provisions thereof.&nbsp;&nbsp;</p>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Exclusive Forum.&nbsp; If any claim, dispute, or controversy occurs between the Parties relating to the interpretation or implementation of any of the provisions of this Agreement, such dispute shall be resolved exclusively by private, confidential and binding arbitration.&nbsp; Such arbitration shall be conducted by a single arbitrator.&nbsp; The arbitrator shall be appointed by agreement of the Parties or, in the absence of an agreement, such arbitrator shall be appointed by the arbitration forum upon the application of either Party.&nbsp; Arbitration shall be held in Wilmington, Delaware, unless otherwise agreed by the Parties in writing.&nbsp; The arbitration procedure to be followed shall be agreed by the Parties or, in absence of an agreement, determined by the arbitrator. The arbitration shall proceed in accordance with the provisions and rules of the<em> </em>American Arbitration Association.<em> </em>Subject to any right of appeal, the decision arrived at by the arbitrator shall be final and binding. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction.</p>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>No Class Action.&nbsp; Without limiting the foregoing, you may only make a claim or proceeding against TI in your individual capacity and shall not as a plaintiff or class member in any purported class or representative action or proceeding.</p>
                </li>
                <li style={{listStyleType: "lower-alpha"}}>
                <p>Notices. All notices required under this Agreement must be delivered by email to the personnel designated below.&nbsp; Such notices shall be effective upon actual receipt by the other Party.</p>
                </li>
               



                <p>To TI: [2368A Rice Blvd, Suite 242, Houston, Texas 77005]</p>
              
                <p>To you: [When you set up your TI Staking Account, you will be instructed to provide your address for notices hereunder.]</p>
                        


                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Independent Contractors.&nbsp; The Parties are independent contractors.&nbsp; Neither Party shall be deemed to be an employee, agent, partner, joint venturer, or legal representative of the other for any purpose, and neither shall have any right, power, or authority to create any obligation or responsibility on behalf of the other.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Severability.&nbsp; If any provision of this Agreement is found invalid or unenforceable by a court of competent jurisdiction, that provision shall be amended to achieve as nearly as possible the same economic effect as the original provision, and the remainder of this Agreement shall remain in full force and effect.&nbsp; Any provision of this Agreement, which is unenforceable in any jurisdiction, shall be ineffective only as to that jurisdiction, and only to the extent of such unenforceability, without invalidating the remaining provisions hereof.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Force Majeure. Neither Party shall be deemed to be in breach of this Agreement for any failure or delay in performance to the extent caused by reasons beyond its reasonable control, including acts of God, epidemics or pandemics, earthquakes, strikes, or shortages and unavailability of materials or resources, and operations and actions of and changes to Blockchain Protocols (&ldquo;Force Majeure Events&ldquo;).</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Third-Party Beneficiaries. Except as set forth in Section 14, there are no third-party beneficiaries under this Agreement.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Modifications. Any modification or amendment to this Agreement must be in writing signed by both Parties or is null and void.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Without limiting the foregoing, if there would otherwise be any legally binding agreement involving you and TI that is implied by or embodied in the protocols of the Supported Blockchain that conflicts or is inconsistent with this Agreement, this Agreement shall prevail over such other agreement to the extent of the inconsistency.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Currency. All dollar ($) amounts identified in this Agreement are denominated in U.S. dollars.</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>For avoidance of doubt, if you enter into a direct Staking and Delegation Agreement with TI, then the terms of such agreement shall be enforced as a matter of priority over the provisions of these Terms and Conditions.&nbsp;</p>
                  </li>
                 <li style={{listStyleType: "lower-alpha"}}>
                  <p>Entire Agreement. This Agreement constitutes the final and complete agreement between the Parties regarding the subject matter hereof, and supersedes any prior or contemporaneous communications, representations, or agreements between the Parties, whether oral or written.&nbsp; No term included in any confirmation, acceptance, or any other similar document from you in connection with this Agreement will apply to this Agreement or have any force or effect. This Agreement shall be effective upon any delegation of Tokens to TI by you notwithstanding the non-execution or delivery of this Agreement by either Party.</p>
                  </li>

                  </ol>
              </li>

    
            



   
              </ul>
              
            
            </div>
        </div>
      </div>
      </div>
      <div className="h-[400px]" />
    </Layout>
  );
}